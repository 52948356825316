import { applyVueInReact } from "vuereact-combined";
import React, { Component } from "react";
import axios from "@/axios";
import LoadingSpinner from "@/components/LoadingSpinner";
import Payment from "../../components/payment/Payment";

class InternetBillArrownet extends Component {
  LoadingSpinnerComp = applyVueInReact(LoadingSpinner);

  constructor() {
    super();
    this.state = {
      message: "",
      reports: "",
      userName: "",
      loading: true,
      selectedPlan: "",
      banks: "",
      pinVerified: false,
      serviceId: "",
      reward: 0,
      paymentModal: false,
      pin: "",
    };
  }
  componentDidMount() {
    this.setState({ loading: false });
  }

  validationBeforePayment = () => {
    if (this.state.selectedPlan === "") {
      this.setState({ message: "Please select the plan." });
      return false;
    }
    return true;
  };

  handleUserNameChange = (event) => {
    this.setState({ userName: event.target.value });
  };

  checkArrownetAccount = async (event) => {
    event.preventDefault();
    if (!this.state.userName) {
      this.setState({ message: "Please enter your Arrownet UserName." });
      return;
    }
    this.setState({ loading: true });
    const data = await axios.post("/api/v2/services/arrownet/checkAccount", {
      ArrownetUserName: this.state.userName,
    });
    this.setState({ loading: false, message: "" });
    if (data.data && data.data.response) {
      if (data.data.response.Code !== "000") {
        this.setState({ message: data.data.response.Message });
      } else {
        this.setState({ reports: data.data.response });
      }
    }
  };
  handlePlanChange = (event) => {
    const data = event.target.value ? JSON.parse(event.target.value) : "";
    this.setState({ selectedPlan: data, message: "" });
  };
  render() {
    return (
      <div className="container">
        <div className="wallet-form-box card">
          <div className="card-body">
            <h5 className="card-title">Arrownet Payment.</h5>
            <a href="/" className="btn btn-close">
              <i className="fa fa-times"></i>
            </a>
            {this.state.message && (
              <div className="form-group fade-animation">
                <div className="show-message">{this.state.message}</div>
              </div>
            )}
            <div className="row">
              <div className="col-md-7">
                {this.state.reports ? (
                  <div className="fade-animation">
                    <div className="mt-4">
                      <div className="font-weight-bold">Arrownet UserName</div>
                      <div>{this.state.reports.ArrownetUserName}</div>
                    </div>
                    <div className="mt-4">
                      <div className="font-weight-bold">Customer Name</div>
                      <div>{this.state.reports.CustomerName}</div>
                    </div>
                    <div className="mt-4">
                      <div className="font-weight-bold">Payment Message</div>
                      <div>{this.state.reports.PaymentMessage}</div>
                    </div>
                    {/* SELECT PLAN DIVISION */}
                    <div className="form-group mt-4">
                      <select
                        className="custom-select"
                        name="planId"
                        onChange={this.handlePlanChange}
                      >
                        <option value="">-- Select Plan for Renewal --</option>
                        {this.state.reports.RenewalPlans.map((plan) => {
                          return (
                            <option
                              key={plan.PlanId}
                              value={JSON.stringify(plan)}
                            >
                              {plan.PlanName}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                    {/* Payment Division */}
                    <div className="fade-animation">
                      <Payment
                        paymentUri="api/v4/arrownet/payment"
                        paymentPayload={{
                          ArrownetUserName: this.state.userName,
                          PlanId: this.state.selectedPlan.PlanId,
                          Amount: this.state.selectedPlan.PlanAmount,
                        }}
                        setMessage={(message) => {
                          this.setState({ message });
                        }}
                        validationBeforePayment={this.validationBeforePayment}
                        paymentPreviewDetail={{
                          "Customer Name": this.state.reports.CustomerName,
                          "Total Amount to Pay":
                            "Rs. " + this.state.selectedPlan.PlanAmount,
                        }}
                      />
                    </div>
                  </div>
                ) : (
                  <form>
                    <div className="form-group">
                      <label>
                        Username<i className="fa fa-asterisk"></i>
                      </label>
                      <input
                        className="form-control"
                        type="text"
                        placeholder="Type Username and press enter."
                        value={this.state.userName}
                        onChange={this.handleUserNameChange}
                      />
                      <button
                        className="btn btn-primary mt-3"
                        onClick={this.checkArrownetAccount}
                      >
                        Search
                      </button>
                    </div>
                  </form>
                )}
              </div>
            </div>
            <this.LoadingSpinnerComp loading={this.state.loading} />
          </div>
        </div>
      </div>
    );
  }
}

export default InternetBillArrownet;
